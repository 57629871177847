import React from "react"
import { Splash } from "../../components/index"

const FreeResource = () => {
  return (
    <Splash
      title="Increase Your Website Conversions With These 5 Key Website
      Elements"
      type="freebie"
    />
  )
}

export default FreeResource
